import React from "react";
import { Link } from "gatsby";
import Layout from "components/layout";
import Image from "components/image";
import Style from "styles/index.module.scss";
import SEO from "components/seo";
import LinkButton from "components/link_button";
import BackgroundVideo from "components/background_video";

import Rest from "images/rest.jpg";

const Index = () => {
  const videoSrc = {
    url: "https://coporate-site-video.s3.ap-northeast-1.amazonaws.com/rest.mp4",
    poster: Rest,
  };
  return (
    <>
      <SEO title="e-ize" />
      <Layout>
        <div className={Style.container}>
          <div className={Style.Content}>
            <section className={Style.top}>
              <div className={Style.top__inner}>
                <BackgroundVideo videoSrc={videoSrc} />
                <div className={Style.topCatch}>
                  <div className={Style.topCatch__en}>
                    CREATE
                    <br />
                    THE
                    <br />
                    FUTURE
                  </div>
                  {/* <div className={Style.topCatch__ja}>未来を創造する</div> */}
                </div>
                <div className={Style.top__img__award__banner__wrapper}>
                  <Link to="https://www.your-intern.com/award/2021/">
                    <Image className={Style.top__img__award__banner} filename="award2021.png" />
                  </Link>
                  <Link
                    target="_blunk"
                    to="https://www.telework-rule.metro.tokyo.lg.jp/search/details.php?app_form_id=647578"
                  >
                    <Image className={Style.top__img__award__banner_margin} filename="logo3.png" />
                  </Link>
                  <Link to="https://comachi-study.comachicart.com/">
                    <Image className={Style.top__img__award__banner} filename="ecworkshop.png" />
                  </Link>
                </div>
              </div>
            </section>
            <section className={Style.service}>
              <div className={Style.service__inner}>
                <h2
                  className={Style.service__head}
                  data-sal="fade"
                  data-sal-duration="300"
                  data-sal-delay="300"
                  data-sal-easing="ease-out"
                >
                  SERVICE
                </h2>
                <p className={Style.service__txt}>
                  ビジネスモデルや組織の変革のために企業のDXが加速している現代。
                  <br />
                  私たちは市場や関係者様の要望に対し、組織一丸となって最適なソリューションをご提供します。
                </p>
                <div
                  className={Style.serviceItem}
                  data-sal="fade"
                  data-sal-duration="300"
                  data-sal-delay="300"
                  data-sal-easing="ease-out"
                >
                  <Link className={Style.serviceItem__link} to="/comachicart/">
                    <div className={Style.serviceItem__txtWrapper}>
                      <h3 className={Style.serviceItem__head}>comachicart</h3>
                      <p className={Style.serviceItem__txt}>ショッピングカートサービスをご提供</p>
                    </div>
                    <div className={Style.serviceItem__imgWrapper}>
                      <Image
                        className={`${Style.serviceItem__img} ${Style.serviceItem__img__comachi__pc}`}
                        filename="img_top_comachi_minimize.png"
                      />
                      <Image
                        className={`${Style.serviceItem__img} ${Style.serviceItem__img__comachi__sp}`}
                        filename="img_top_comachi_sp.png"
                      />
                    </div>
                  </Link>
                </div>
                <div
                  className={Style.serviceItem}
                  data-sal="fade"
                  data-sal-duration="300"
                  data-sal-delay="300"
                  data-sal-easing="ease-out"
                >
                  <Link className={Style.serviceItem__link} to="/service/#ses">
                    <div className={Style.serviceItem__txtWrapper}>
                      <h3 className={Style.serviceItem__head}>SES</h3>
                      <p className={Style.serviceItem__txt}>優秀な人材をご提供</p>
                    </div>
                    <div className={Style.serviceItem__imgWrapper}>
                      <Image className={Style.serviceItem__img} filename="network.jpg" />
                    </div>
                  </Link>
                </div>
                <div
                  className={Style.serviceItem}
                  data-sal="fade"
                  data-sal-duration="300"
                  data-sal-delay="300"
                  data-sal-easing="ease-out"
                >
                  <Link className={Style.serviceItem__link} to="/service/#development">
                    <div className={Style.serviceItem__txtWrapper}>
                      <h3 className={Style.serviceItem__head}>Development</h3>
                      <p className={Style.serviceItem__txt}>最大限のソリューションをご提供</p>
                      <div className={Style.serviceItem__imgWrapper}>
                        <Image className={Style.serviceItem__img} filename="code_white.jpg" />
                      </div>
                    </div>
                  </Link>
                </div>
                <div className={Style.service__buttonBox}>
                  <LinkButton className={Style.service__button} color="black" text="MORE" url="/service/" />
                </div>
              </div>
            </section>
            <section className={Style.advantage}>
              <div className={Style.advantage__inner}>
                <h2
                  className={Style.advantage__head}
                  data-sal="fade"
                  data-sal-duration="300"
                  data-sal-delay="300"
                  data-sal-easing="ease-out"
                >
                  ADVANTAGE
                </h2>
                <div
                  className={Style.advantage__img}
                  data-sal="fade"
                  data-sal-duration="300"
                  data-sal-delay="300"
                  data-sal-easing="ease-out"
                >
                  <Image filename="hand.jpg" />
                </div>

                <div className={Style.advantageContent}>
                  <div className={Style.advantageContent__inner}>
                    <p className={Style.advantageContent__txt}>
                      社員のアイデアや要望を形にした自社システム。
                      エンジニアとしてのスキルアップを目的としたワークショップ。
                      各々のキャリアパス形成をサポートする目標管理制度。
                      イーゼには新しい技術を吸収し、形にできる環境があります。
                    </p>
                    <div className={Style.advantageContent__buttonBox}>
                      <LinkButton
                        className={Style.advantageContent__button}
                        color="black"
                        text="ABOUT US"
                        url="/about/"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </section>

            <section className={Style.recruit}>
              <div className={Style.recruit__bg}>
                <div className={Style.recruit__inner}>
                  <h2
                    className={Style.recruit__head}
                    data-sal="fade"
                    data-sal-duration="300"
                    data-sal-delay="300"
                    data-sal-easing="ease-out"
                  >
                    RECRUIT
                  </h2>
                  <div className={Style.recruitContent}>
                    <p className={Style.recruitContent__txt}>
                      イーゼは、エンジニアの主体性を最大限に尊重し一人ひとりのキャリアパスに真剣に向き合います。
                      <br />
                      私たちと一緒に挑戦してくれる仲間をお待ちしております。
                    </p>
                  </div>
                  <div className={Style.recruitContent__buttonBox}>
                    <LinkButton className={Style.recruitContent__button} color="white" text="JOIN US" url="/recruit/" />
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </Layout>
    </>
  );
};

export default Index;
